
window._ = require('lodash');
window.Popper = require('popper.js').default;
window.$ = window.jQuery = window.jquery = require('jquery');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.moment = require('moment');
window.Vue = require('vue');
window.ApexCharts = require('apexcharts');
var datatables = $(".datatables");
window.datatables = datatables;


require('bootstrap');
require('datatables.net/js/jquery.dataTables');
require('datatables.net-bs4/js/dataTables.bootstrap4');
require('datatables.net-responsive/js/dataTables.responsive');
require('datatables.net-responsive-bs4/js/responsive.bootstrap4');
require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4');
require('bootstrap-select');
require("summernote/dist/summernote-bs4");
require("../plugins/summernote-list-styles/summernote-list-styles-bs4");
require("jquery");

// sidebar scripts
$(document).ready(function () {
    $(".menu-dropdown-toggle").click(function () {
        let elem = $(this);
        elem = elem.parent();

        let hide = elem.find("div[class='menu-dropdown-items']");
        let show = elem.find("div[class='menu-dropdown-items show']");

        if (show.length) {
            $(show[0]).removeClass("show");
        } else if (hide.length) {
            $(hide[0]).addClass("show");
        }
    });
});

// tempus dattimepicker init
$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
    icons: {
        time: 'fas fa-clock',
        date: 'fas fa-calendar',
        up: 'fas fa-arrow-up',
        down: 'fas fa-arrow-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right',
        today: 'fas fa-calendar-check-o',
        clear: 'fas fa-trash',
        close: 'fas fa-times'
    }
});

$(document).ready(function () {
    var dateTimePickerInput = $('.datetimepicker-input');

    $.each(dateTimePickerInput, function (indexInArray, valueOfElement) {
        var dateTimePicker = $(valueOfElement);

        dateTimePicker.datetimepicker({
            format: dateTimePicker.data('format'),
            date: moment(dateTimePicker.data('value'), dateTimePicker.data('format'))
        });
    });
});
// end tempus dattimepicker init

// datatables
$(document).ready(function () {
    if (datatables.length) {
        $.each(datatables, function (index, value) {
            let datatable = $(value);
            let url = datatable.data("datatable-structure-url");
            initDatatables(url, datatable);
        });
    }

    function initDatatables(url, datatables) {
        axios.get(url)
            .then(function (response) {
                drawDatatables(response.data, datatables)
            });
    }

    function drawDatatables(structure, datatables) {
        var searching = true;
        var paging = true;
        var ordering = true;
        var info = true;
        var scrollX = true;

        if (structure.options != null) {
            if (typeof structure.options.searching != 'undefined') {
                searching = structure.options.searching;
            }

            if (typeof structure.options.paging != 'undefined') {
                paging = structure.options.paging;
            }

            if (typeof structure.options.ordering != 'undefined') {
                ordering = structure.options.ordering;
            }

            if (typeof structure.options.info != 'undefined') {
                info = structure.options.info;
            }

            if (typeof structure.options.scrollX != 'undefined') {
                scrollX = structure.options.scroll_x;
            }
        }

        datatables = datatables.DataTable({
            processing: true,
            serverSide: true,
            ordering: ordering,
            searching: searching,
            info: info,
            paging: paging,
            order: structure.options.default_sort,
            ajax: {
                url: structure.options.url,
                data: function data(d) {
                    $("#datatables-filters :input").each(function () {
                        var name = $(this).attr('name');
                        var val = $(this).val();
                        d[name] = val;
                    });
                }
            },
            scrollX: scrollX,
            response: true,
            columns: structure.datas
        });

        window.datatables = datatables;
    }
});
// end datatables

// summernote
$('.summernote').summernote({
    minHeight: "300px",
    toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'listStyles', 'paragraph']],
        ['height', ['height']],
        ['table', ['table']],
    ],
    tabDisable: false
});
// end summernote

// app

window.openModalDelete = function (elem) {
    let deleteUrl = $(elem).data("delete-url");
    $("#form-delete").attr("action", deleteUrl);
    $("#modal-delete").modal("show");
};
// end app

// sidebar
$("#sidebar-toggle").click(function (e) {
    $(".sidebar").addClass("show");
});

$("#sidebar-close-button").click(function (e) {
    $(".sidebar").removeClass("show");
});
// end sidebar
window.showToast = function (data = { title: "Notification", message: "Success", type: "success" }) {
    $(".toast-header").removeClass("bg-primary");
    $(".toast-header").removeClass("bg-warning");
    $(".toast-header").removeClass("bg-danger");
    $(".toast-header").addClass(`bg-${data.type}`);
    $('.toast').toast('show');
    $('#toast-title').html(data.title);
    $('#toast-message').html(data.message);
}
// toast



// end toast


// preloader

$(".preloader").addClass("show");

$(document).ready(function () {
    setTimeout(() => {
        $(".preloader").removeClass("show");
    }, 3000);
});
// end preloader


// tooltip
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})
// end tooltip
